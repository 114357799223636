import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

// import "./layout.css"
// import "milligram/dist/milligram.css"
import "./milligram.css"
import "./custom.css"

const ContentContainer = styled.main`
  margin: 0.5em auto;
  max-width: 800px;
  padding: 1em;
  line-height: 1.4;
  text-align: justify;
  grid-area: content;
`

const Header = styled.div`
  grid-area: header;
`

const LeftSide = styled.div`
  grid-area: nav;
  margin-left: 0.5rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const RightSide = styled.div`
  grid-area: side;
  margin-right: 0.5rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const Footer = styled.div`
  grid-area: footer;
`

const PageContainer = styled.div`
  display: grid;

  grid-template-areas:
    "header header header"
    "nav content side"
    "footer footer footer";

  grid-template-columns: 200px 1fr 200px;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;

  height: 100vh;
  @media (max-width: 768px) {
    grid-template-areas:
      "header"
      "nav"
      "content"
      "side"
      "footer";

    grid-template-columns: 1fr;
    grid-template-rows:
      auto /* Header */
      minmax(75px, auto) /* Nav */
      1fr /* Content */
      minmax(75px, auto) /* Sidebar */
      auto; /* Footer */
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <PageContainer>
        <Header className="navbar">
          <ul>
            <li>
              <Link className="active" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </Header>
        <LeftSide />
        <ContentContainer>{children}</ContentContainer>
        <RightSide />
        <Footer />
      </PageContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
